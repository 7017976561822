import { Suspense, useEffect } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { JobsProvider } from '../../context/JobsContext';
import { useAuth } from '../../context/AuthContext';
import Account from '../pages/Account';
import EditCarrier from '../pages/EditCarrier';
import Dashboard from '../pages/Dashboard';
import EditJob from '../pages/EditJob';
import UserManager from '../pages/UserManager';
import AdminRoute from './AdminRoute';
import UserRoute from './UserRoute';
import UnprotectedRoutes from './UnprotectedRoutes';
import LoadingFallback from '../transition/LoadingFallback';
import Questionnaire from '../pages/Questionnaire';
import Welcome from '../pages/Welcome';
import ReactGA from 'react-ga4';
import { useMutation } from '@apollo/client';
import { Mutations } from '../../util/Graphql';
import Loader from '../transition/Loader';

const Logout = () => {
  const { logout } = useAuth();
  const [ logoutFunction, { loading } ] = useMutation(
    Mutations.LOGOUT,
    { onError(e) { console.error(e); } } // Log & swallow error
  );
  
  useEffect(() => {
    logoutFunction().finally(() => { logout(true); });
  }, [logoutFunction]);

  if (loading)
    return <Loader />;
  
  return <Redirect to='/login' />;
};

const AppRoutes = () => {
  const { getUserInfo, isUser } = useAuth();
  const history = useHistory();

  useEffect(() => {
    const unlisten = history.listen(() => {
      const trackingId = process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID;
      if (trackingId) {
        if (isUser()) {
          const testMode = process.env.REACT_APP_GOOGLE_ANALYTICS_TEST_MODE === '1';
          if (getUserInfo()) {
            ReactGA.initialize(trackingId, { testMode: testMode, gaOptions: { clientId: getUserInfo()?.id }});
            ReactGA.set({ userId: getUserInfo()?.id });
            ReactGA.event({
              category: 'meta',
              action: 'userId',
              label: getUserInfo()?.id, // id unique to current page load
              value: 1, // values must be integers
              nonInteraction: true, // avoids affecting bounce rate
              transport: 'xhr'
            });
          } else {
            ReactGA.initialize(trackingId, { testMode: testMode });
          }
        }

        ReactGA.send('pageview');
      }
    });

    return () => unlisten();
  }, [history, isUser, getUserInfo]);

  const getDashboardRoute = () => (
    <JobsProvider>
      <Dashboard />
    </JobsProvider>
  );

  return (
    <Suspense fallback={<LoadingFallback />}>
      <Switch>
        <Route path='/logout' component={Logout} />
        <UserRoute path='/welcome'>
          <Welcome />
        </UserRoute>
        <UserRoute path='/editcarrier'>
          <JobsProvider>
            <EditCarrier />
          </JobsProvider>
        </UserRoute>
        <UserRoute path='/dashboard/job/:jobId/conversation/:conversationId'>
          {getDashboardRoute()}
        </UserRoute>
        <UserRoute path='/dashboard/job/:jobId/:tabName?'>
          {getDashboardRoute()}
        </UserRoute>
        <UserRoute path='/dashboard/:mainTabName?'>
          {getDashboardRoute()}
        </UserRoute>
        <UserRoute path='/editjob/:jobId?'>
          <JobsProvider>
            <EditJob />
          </JobsProvider>
        </UserRoute>
        <UserRoute path='/questionnaire/:questionnaireId/:section'>
          <Questionnaire />
        </UserRoute>
        <UserRoute path='/account'>
          <Account />
        </UserRoute>
        <AdminRoute path='/userManager'>
          <UserManager />
        </AdminRoute>
        <UnprotectedRoutes />
      </Switch>
    </Suspense>
  );
};

export default AppRoutes;