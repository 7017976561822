import { useState, HTMLAttributes, MouseEvent, useLayoutEffect, useEffect, useRef, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { format } from 'date-fns';
import { ApolloError, useLazyQuery, useMutation } from "@apollo/client";
import { useModal } from '../../../context/ModalContext';
import { useJobs } from '../../../context/JobsContext';
import { getErrorMessage, Mutations, Queries } from '../../../util/Graphql';
import { ConversationModel, JobInterestStatus, JobModel, MatchScore, UNAUTHENTICATED } from '../../../Typings';
// import { ReactComponent as FilterSvg } from '../../../assets/Filter.svg'
// import { ReactComponent as SortSvg } from '../../../assets/Sort.svg'
import { ReactComponent as ChevronSvg } from '../../../assets/Chevron.svg'
import { ReactComponent as CheckSvg } from '../../../assets/Check.svg'
import { PersonAvatar } from '../cloudinary/PersonAvatar'
import Navbar, { NavEnd, NavStart } from '../../common/Navbar';
// import DropdownMenu from '../../common/DropdownMenu';
import Divider from '../../common/Divider';
import MatchPercent from './MatchPercent';
import SearchBox from '../../common/SearchBox';
import Slideout from '../../common/Slideout';
import JobConversation from './JobConversation';
import DriverProfile from './DriverProfile';
import Button, { ButtonTheme } from '../../common/Button';
import Loader from '../../transition/Loader';
import EditConfirmText from '../../dialogs/EditConfirmText';
import './JobConversations.css';

export type JobConversationsProps = {
  job: JobModel;
};

type ConversationItemModel = {
  conversationId: string,
  lastName: string,
  firstName: string,
  fullName: string,
  photoUrl: string,
  matchScore: MatchScore,
  messageDate: string,
  messageText: string,
  messageReadDate: string,
  messageSentByCandidate: boolean,
  conversation: ConversationModel
};

interface IConversationItemProps extends HTMLAttributes<HTMLDivElement> {
  isLast?: boolean;
  item: ConversationItemModel;
  isSelected: boolean;
  isSlideoutVisible: boolean;
}

const ConversationItem = ({ isLast, item: { conversationId, fullName, photoUrl, matchScore, messageDate, messageText, messageReadDate, messageSentByCandidate /*, messageIsIntroduction */ }, isSelected, isSlideoutVisible, ...props }: IConversationItemProps) => {
  return (
    <>
      <div className={`driver-item${isSelected ? ' selected' : ''}`} data-index={`${conversationId}`} {...props}>
        <div className='driver-item-images'>
          <PersonAvatar publicId={photoUrl} size={36} className='driver-item-img-avatar' />
          <ChevronSvg className={`driver-item-img-chevron${isSlideoutVisible ? ' show' : ''}`} />  
        </div>
        <div className={`driver-item-rows${isSlideoutVisible ? ' hide' : ''} fs-14 ls-2`}>
          <div className='driver-item-top-row'>
            <div>
              <span className='off-black fw-5' style={{ marginRight: '15px'}} title={fullName}>{fullName}</span>
              <MatchPercent matchScore={matchScore} className='fs-12' style={{ marginBottom: '1px'}} />
            </div>
            <span className='driver-item-message-date medium-gray'>{messageDate}</span>
            <ChevronSvg className='driver-item-img-chevron' />
          </div>
          <div className={`driver-item-bottom-row${messageSentByCandidate ? ' from-driver' : ''} medium-gray`}>
            {/* {messageSentByCandidate && <span className='fw-5'>{firstName}</span>} */}
            <div className='driver-item-img-check-container'>
              {messageReadDate ? <CheckSvg className={`driver-item-img-check ${messageSentByCandidate ? 'driver' : 'carrier'}`} title={messageReadDate} /> : null}
            </div>
            {/* <span>{messageIsIntroduction ? ' sent an introduction' : messageText}</span> */}
            <span title={messageText}>{messageText}</span>
          </div>
        </div>
      </div>
      { !isLast && <Divider />}
    </>
  );
};

const JobConversations = ({ job }: JobConversationsProps) => {
  const { carrier, refresh: refreshJobsContext  } = useJobs();
  const { compose, destroy } = useModal();
  const [ toggleSlideout, setToggleSlideout ] = useState<boolean | undefined>();
  const [ isSlideoutVisible, setIsSlideoutVisible ] = useState(false);
  const [ conversationItems, setConversationItems ] = useState<Array<ConversationItemModel>>();
  const [ activeConversation, setActiveConversation ] = useState<ConversationItemModel>();
  const [ searchFilter, setSearchFilter ] = useState<string>('');
  const { jobId: jobIdParam } = useParams<{ jobId: string | undefined }>();
  const { conversationId: conversationIdParam } = useParams<{ conversationId: string | undefined }>();
  const history = useHistory();
  const elementRef = useRef<HTMLDivElement>(null);

  const getConversationItemModels = (data: any) : Array<ConversationItemModel> => {
    const conversationItems = data.carrierJobConversations
      // .sort((c1: ConversationModel, c2: ConversationModel) => (new Date(c2.lastMessage?.createdAt)).getTime() - (new Date(c1.lastMessage?.createdAt)).getTime())
      .map((conversation: ConversationModel) => 
        ({
          conversationId: conversation.id,
          lastName: conversation.driver?.lastName,
          firstName: conversation.driver?.firstName,
          fullName: `${conversation.driver?.firstName} ${conversation.driver?.lastName}`,
          photoUrl: conversation.driver?.photoUrl,
          matchScore: conversation.matchScore,
          messageDate: conversation.lastMessage?.createdAt ? new Date(conversation.lastMessage?.createdAt).toLocaleDateString('en-US') : '',
          messageText: conversation.lastMessage?.message,
          messageReadDate: conversation.lastMessage?.readAt ? format(new Date(conversation.lastMessage?.readAt), 'M/d/yy h:mm aa') : '',
          messageSentByCandidate: (conversation.lastMessage?.from?.Id === conversation.driver?.id), // TODO: DFC - Can't figure out why this seems to always set value to false...
          conversation: conversation
        }) as ConversationItemModel) as Array<ConversationItemModel>;

    return conversationItems;
  };

  const [ getJobConversationsFunction, { loading: jobConversationsLoading, refetch: jobConversationsRefetch } ] = useLazyQuery(
    Queries.GET_JOB_CONVERSATIONS, 
    { 
      fetchPolicy: 'no-cache', 
      onError(e) {
        const errorMsg = getErrorMessage(e);
        if (errorMsg === UNAUTHENTICATED) {
          history.push('/login', { isAuthExpired: true });
        } else {
          history.push('/oops', { error: errorMsg });
        }
      },
      onCompleted(data) { 
        if (data) {
          const tempConversationItems = getConversationItemModels(data);
          setConversationItems(tempConversationItems);
        }
      }
    }
  );

  const refreshConversations = useCallback(() => {
    console.log('refreshConversations()');
    if (job) {
      getJobConversationsFunction({
        variables: { jobId: job.id }
      });
    };
  }, [getJobConversationsFunction, job]);

  useEffect(() => {
    const prevTitle = document.title;
    document.title = 'TruckerFy - Job Conversations';

    return () => { document.title = prevTitle; };
  }, []);

  useEffect(() => {
    if (elementRef?.current) {
      let selectedElements = elementRef.current.getElementsByClassName('driver-item selected');
      if (selectedElements.length){
        selectedElements[0].scrollIntoView({behavior: 'auto', block: 'nearest'});
      }
    }
  }, [activeConversation]);

  useLayoutEffect(() => {
    if (conversationIdParam && conversationItems) {
      const selectedConversation = conversationItems.find(ci => ci.conversationId === conversationIdParam);
      if (selectedConversation)
        setActiveConversation(selectedConversation);
      else
        history.push('/oops', 'Invalid parameters passed to dashboard page.');
    }
  }, [conversationIdParam, conversationItems, history]);

  useLayoutEffect(() => refreshConversations(), [job, refreshConversations]);
  
  /*
  const handleSortItemSelect = (text: string, index: number) => {
    compose(<Announcement announcementMessage={`Sorting Conversations has not yet been implemented.`}/>);
  };

  const sortMenuItemsArray = [
    'Name', 
    'Best Match', 
    'Most Recent Message'
  ];

  const handleFilterItemSelect = (text: string, index: number) => {
    compose(<Announcement announcementMessage={`Filtering Conversations has not yet been implemented.`}/>);
  };

  const filterMenuItemsArray = [ 
    'New candidates', 
    'Reviewed candidates', 
    'Rejected candidates', 
    'All candidates'
  ];
  */

  const handleSeeProfileClick = () => {
    // console.log('handleSeeProfileClick()');
    setToggleSlideout(prevValue => !prevValue);
  };

  const onSlideoutVisibleChange = (isVisible: boolean) => {
    // console.log(`onSlideoutVisibleChange(${isVisible})`);
    setIsSlideoutVisible(isVisible); 
  };

  // useEffect(() => {
  //   if (isSlideoutVisible)
  //     setToggleSlideout(prevValue => !prevValue);
  //   setActiveConversation(undefined);
  // }, [activeJob?.id]);

  const [ updateJobInterestStatusAndSendMessageToDriverFunction, { loading: updateJobInterestStatusAndSendMessageToDriverLoading } ] = useMutation(
    Mutations.UPDATE_JOB_INTEREST_STATUS_AND_SEND_MESSAGE_TO_DRIVER, 
  );
  
  const handleDoesNotQualifyOk = (msg: string) => {
    destroy();
    if (activeConversation?.conversation?.jobInterest) {
      updateJobInterestStatusAndSendMessageToDriverFunction({
        variables: { 
          jobInterestId: activeConversation.conversation.jobInterest.id,
          status: JobInterestStatus.Rejected,
          message: msg
        }
      })
      .then((res: any) => {
        refreshJobsContext();
        refreshConversations();
      })
      .catch((e: ApolloError) => {
        const errorMsg = getErrorMessage(e);
        if (errorMsg === UNAUTHENTICATED) {
          history.push('/login', { isAuthExpired: true });
        } else {
          history.push('/oops', { error: errorMsg });
        }
      });
    }
  };

  const handleDoesNotQualifyClick = () => {
    if (activeConversation?.conversation?.jobInterest) {
      const prompt = `Disqualify ${activeConversation.conversation.driver?.firstName} ${activeConversation.conversation.driver?.lastName} for the '${activeConversation.conversation.job.internalTitle ?? activeConversation.conversation.job.title}' job and send him/her the following message?`;
      const msg = `Thank you for considering the '${activeConversation.conversation.job.title ?? 'driver'}' position with ${carrier?.name ?? 'us'}. Unfortunately at this time your profile does not meet our criteria. We will keep your information on file in case anything changes and we wish you the best of luck in your job search.`;
      compose(<EditConfirmText prompt={prompt} text={msg} okButtonText='Disqualify &amp; Send' handleOk={handleDoesNotQualifyOk}/>);
    }
  };

  const handleMarkHiredOk = (msg: string) => {
    destroy();
    if (activeConversation?.conversation?.jobInterest) {
      updateJobInterestStatusAndSendMessageToDriverFunction({
        variables: { 
          jobInterestId: activeConversation.conversation.jobInterest.id,
          status: JobInterestStatus.Hired,
          message: msg
        }
      })
      .then((res: any) => {
        refreshJobsContext();
        refreshConversations();
      })
      .catch((e: ApolloError) => {
        const errorMsg = getErrorMessage(e);
        if (errorMsg === UNAUTHENTICATED) {
          history.push('/login', { isAuthExpired: true });
        } else {
          history.push('/oops', { error: errorMsg });
        }
      });
    }
  };

  const handleMarkHiredClick = () => {
    if (activeConversation?.conversation?.jobInterest) {
      const prompt = `Mark ${activeConversation.conversation.driver?.firstName} ${activeConversation.conversation.driver?.lastName} as 'Hired' for the '${activeConversation.conversation.job.internalTitle ?? activeConversation.conversation.job.title}' job and send him/her the following message?`;
      const msg = `Congratulations, you are hired for the '${activeConversation.conversation.job.title ?? 'driver'}' position with ${carrier?.name ?? 'us'}! We're looking forward to having you join our team! `;
      compose(<EditConfirmText prompt={prompt} text={msg} okButtonText='Hire &amp; Send' handleOk={handleMarkHiredOk}/>);
    }
  };

  const handleMakeOfferOk = (msg: string) => {
    destroy();
    if (activeConversation?.conversation?.jobInterest) {
      updateJobInterestStatusAndSendMessageToDriverFunction({
        variables: { 
          jobInterestId: activeConversation.conversation.jobInterest.id,
          status: JobInterestStatus.OfferMade,
          message: msg
        }
      })
      .then((res: any) => {
        refreshJobsContext();
        refreshConversations();
      })
      .catch((e: ApolloError) => {
        const errorMsg = getErrorMessage(e);
        if (errorMsg === UNAUTHENTICATED) {
          history.push('/login', { isAuthExpired: true });
        } else {
          history.push('/oops', { error: errorMsg });
        }
      });
    }
  };

  const handleMakeOfferClick = () => {
    if (activeConversation?.conversation?.jobInterest) {
      const prompt = `Make ${activeConversation.conversation.driver?.firstName} ${activeConversation.conversation.driver?.lastName} an offer for the '${activeConversation.conversation.job.internalTitle ?? activeConversation.conversation.job.title}' job and send him/her the following message?`;
      const msg = `Congratulations! We think you're a fantastic candidate for the '${activeConversation.conversation.job.title ?? 'driver'}' job with ${carrier?.name ?? 'us'} and we'd like to make you an offer.`;
      compose(<EditConfirmText prompt={prompt} text={msg} okButtonText='Make Offer &amp; Send' handleOk={handleMakeOfferOk}/>);
    }
  };

  const composeToolbar = () => (
    <Navbar className='job-bar'>
      <NavStart>
        <SearchBox autoFocus onTextChange={(newValue: string) => setSearchFilter(newValue)} />
        {/* <DropdownMenu 
          root={
            <div className='job-bar-filter-container' title='Sort Conversations'>
              <SortSvg />
              <span>&nbsp;Sort</span>
            </div>
          }
          selectedIndex={3}
          className='job-bar-filter-dropdown-menu'
          handleItemSelect={handleSortItemSelect}
          items={sortMenuItemsArray}
        /> */}
        {/* <DropdownMenu 
          root={
            <div className='job-bar-filter-container' title='Filter Conversations'>
              <FilterSvg />
              <span>&nbsp;Filter</span>
            </div>
          }
          selectedIndex={3}
          className='job-bar-filter-dropdown-menu'
          handleItemSelect={handleFilterItemSelect}
          items={filterMenuItemsArray}
        /> */}
      </NavStart>
      <NavEnd className='nav-end-job-conversations'>
        {
          activeConversation &&
            <>
              <Button theme={ButtonTheme.White} onClick={handleDoesNotQualifyClick} disabled={!activeConversation?.conversation?.jobInterest}>Does Not Qualify</Button>
              <Button theme={ButtonTheme.White} onClick={handleMakeOfferClick} disabled={!activeConversation?.conversation?.jobInterest}>Make Offer</Button>
              <Button theme={ButtonTheme.White} onClick={handleMarkHiredClick} disabled={!activeConversation?.conversation?.jobInterest}>Mark Hired</Button>
              <Button theme={ButtonTheme.Green} onClick={handleSeeProfileClick}>{`${isSlideoutVisible ? 'Hide' : 'Show'} Profile`}</Button>
            </>
        }
      </NavEnd>
    </Navbar>
  );

  const composeConversations = () => {
    const sorted = conversationItems 
      && conversationItems
        .filter(ci => ci.firstName.toLowerCase().includes(searchFilter.toLowerCase()) || ci.lastName.toLowerCase().includes(searchFilter.toLowerCase()))
        .sort((ci1, ci2) => ci1.lastName.localeCompare(ci2.lastName));
    if (sorted) {
      const length = sorted?.length;
      return sorted.map((item, index) => 
        <ConversationItem
          key={index}
          isLast={index === (length - 1)}
          item={item}
          isSelected={item.conversationId === activeConversation?.conversationId}
          isSlideoutVisible={isSlideoutVisible}
          onClick={handleConversationItemClick}
        />
      );
    }     
  };

  const handleConversationItemClick = (e: MouseEvent<HTMLDivElement>) => {
    const dataIndex = e.currentTarget.getAttribute('data-index');
    history.push(`/dashboard/job/${jobIdParam}/conversation/${dataIndex}`);
  };

  const composeSlideout = () => {
    return (
      <Slideout inline={true} showInitially={false} toggle={toggleSlideout} onVisibleChange={onSlideoutVisibleChange}>
        { activeConversation?.conversation && <DriverProfile model={activeConversation.conversation} /> }
      </Slideout>
    );
  };
  
  const loading = () => updateJobInterestStatusAndSendMessageToDriverLoading || jobConversationsLoading;

  return (
    <>
      <div className='job-conversations'>
        { loading() && <Loader /> }
        { composeToolbar() }
        <main className='job-conversations-content'>
          <section className={`job-conversations-drivers-container${isSlideoutVisible ? ' shrink' : ''}`} ref={elementRef}>
            <div className='job-conversations-drivers'>
              { composeConversations() }
            </div>
          </section>
          <div className='job-conversations-container'>
            {/* { activeConversation && console.log(`Render JobConversation: ${activeConversation.conversationId}`) } */}
            { activeConversation && <JobConversation key={activeConversation?.conversationId} conversation={activeConversation?.conversation} onSeeProfileClick={handleSeeProfileClick} onRefetchConversations={jobConversationsRefetch } />}
          </div>
          { composeSlideout() }
          </main>
      </div>
    </>
  );
};

export default JobConversations;