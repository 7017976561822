import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useJobs } from '../../../context/JobsContext';
import { useQuestionnaire } from '../../../context/QuestionnaireContext';
import { JobInterestModel, JobModel, JobCandidateSort, UNAUTHENTICATED } from '../../../Typings';
import { getErrorMessage } from '../../../util/Graphql';
import { PreferenceType, usePref } from '../../../context/PreferenceContext';
import Grid, { ColumnType } from '../../common/Grid';
import Navbar, { NavStart } from '../../common/Navbar';
import Button, { ButtonTheme } from '../../common/Button';
import DriverProfile from './DriverProfile';
import MatchRibbon from './MatchRibbon';
import GridSort, { UserSort } from '../../common/GridSort';
import Slideout from '../../common/Slideout';
import SearchBox from '../../common/SearchBox';
import './JobCandidates.css';

export type JobCandidatesProps = {
  job: JobModel;
};

const JobCandidates = ({ job }: JobCandidatesProps) => {
  const questionnaireService = useQuestionnaire();
  const { getPreference, setPreference, deletePreference } = usePref();
  const { findOrCreateConversationWithDriver } = useJobs();
  const [ toggleSlideout, setToggleSlideout ] = useState<boolean | undefined>();
  const [ isSlideoutVisible, setIsSlideoutVisible ] = useState(false);
  const [ jobInterests, setJobInterests ] = useState<Array<JobInterestModel>>([]);
  const [ gridData, setGridData ] = useState<Array<JobInterestModel>>([]);
  const [ activeJobInterest, setActiveJobInterest ] = useState<JobInterestModel>();
  const [ toggleClearGridSortColumn, setToggleClearGridSortColumn ] = useState(false);
  const [ searchFilter, setSearchFilter ] = useState<string>('');
  const [ activeSort, setActiveSort ] = useState<UserSort<JobCandidateSort>[]>(getPreference(PreferenceType.JobCandidateSort));

  const history = useHistory();

  const onSlideoutVisibleChange = (isVisible: boolean) => setIsSlideoutVisible(isVisible); 
  
  useEffect(() => {
    const prevTitle = document.title;
    document.title = 'TruckerFy - Job Candidates';

    return () => { document.title = prevTitle; };
  }, []);

  const getEndorsements = useCallback((profile: any) => {
    const answerTextArray = questionnaireService.driverQuestionnaire?.getMultipleAnswerTextArray('endorsements', profile);
    if (Array.isArray(answerTextArray) && answerTextArray.length > 0) {
      return answerTextArray
        .filter(ans => ans.length > 0)
        .map(ans => ans[0].toUpperCase())
        .join(', ');
    }
    return null;
  }, [questionnaireService.driverQuestionnaire]);
  
  const getExperience = useCallback((profile: any) => {
    const experienceYears = questionnaireService.driverQuestionnaire?.getSingleAnswerText('experience', profile);
    return experienceYears ? `${experienceYears} years` : null;
  }, [questionnaireService.driverQuestionnaire]);

  const numberCompare = useCallback((left: number, right: number) => {
    let sortVal = left === right ? 0 : left < right ? -1 : 1;
    return sortVal;
  }, []);

  const matchCompare = useCallback((left: any, right: any) => {
    return numberCompare(right.matchScore?.score ?? -1, left.matchScore?.score ?? -1);
  }, [numberCompare]);

  const statusCompare = useCallback((left: any, right: any) => {
    let sortVal = left.displayStatus.localeCompare(right.displayStatus);
    if (sortVal === 0) {
      sortVal = matchCompare(right, left);
    }
    return sortVal;
  }, [matchCompare]);

  useEffect(() => {
    if (job?.jobInterests) {
      const tempJobInterests = job.jobInterests
        .map((ji: any) => ({
          id: ji.id,
          jobId: job.id,
          driverName: ji.driver.firstName + ' ' + ji.driver.lastName,
          status: ji.activeStatus,
          displayStatus: ji.displayStatus,
          matchScore: ji.matchScore,
          cdl: questionnaireService.driverQuestionnaire?.getSingleAnswerText('cdlClass', ji.driver.profile),
          endorsements: getEndorsements(ji.driver.profile),
          experience: getExperience(ji.driver.profile),
          workRoutes: questionnaireService.driverQuestionnaire?.getMultipleAnswerTextCsv('workRoutes', ji.driver.profile),
          driver: ji.driver,
          conversation: ji.conversation,
          driverIsMessageable: ji.driverIsMessageable
        }) as JobInterestModel) as Array<JobInterestModel>;

      setJobInterests(tempJobInterests);
    }
  }, [job, getEndorsements, getExperience, questionnaireService.driverQuestionnaire]);

  const composeJobInterestMatch = (item: JobInterestModel) => <MatchRibbon matchScore={item.matchScore} className='match-ribbon' />;

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'New': return 'var(--forest-green)';
      case 'Active': return 'var(--colorado-blue)';
      case 'Inactive': return 'var(--medium-gray)';
    }
  };

  const composeJobInterestStatus = (item: JobInterestModel) =>
    <div className='job-applicant fw-5'>
      <span style={{color: getStatusColor(item.status)}}>{item.displayStatus}</span>
    </div>;

  const getColumns = () =>
    new Map([
      [ 'id', { type: ColumnType.Key } ],
      [ 'driverName', { caption: 'Name', type: ColumnType.Text } ],
      [ 'status', { caption: 'Status', type: ColumnType.Custom, renderer: composeJobInterestStatus, sorter: (left: any, right: any) => statusCompare(left, right) } ],
      [ 'matchScore', { caption: 'Matching', type: ColumnType.Custom, renderer: composeJobInterestMatch, sorter: (left: any, right: any) => matchCompare(left, right) } ],
      [ 'cdl', { caption: 'CDL', type: ColumnType.Text } ],
      [ 'endorsements', { caption: 'Endorsements', type: ColumnType.Text, noSort: true } ],
      [ 'experience', { caption: 'Experience', type: ColumnType.Text, noSort: true } ],
      [ 'workRoutes', { caption: 'Work routes', type: ColumnType.Text, noSort: true } ],
    ]);

  const gridRowClickHandler = (key: any) => {
    const jobInterest = jobInterests.find(ji => ji.id === key);
    if (jobInterest) {
      setActiveJobInterest(jobInterest);
    }

    if (!isSlideoutVisible)
      setToggleSlideout(prevValue => !prevValue);
  };

  const handleContactDriverClick = async () => {
    if (activeJobInterest?.jobId && activeJobInterest?.driver?.id) {
      const conversationId = await findOrCreateConversationWithDriver(activeJobInterest.jobId, activeJobInterest?.driver?.id)
        .catch((e: any) => {
          const errorMsg = getErrorMessage(e);
          if (errorMsg === UNAUTHENTICATED) {
            history.push('/login', { isAuthExpired: true });
          } else {
            history.push('/oops', { error: errorMsg });
          }
        });

      if (conversationId)
        history.push(`/dashboard/job/${activeJobInterest.jobId}/conversation/${conversationId}`)
    }
  };

  const composeSlideoutCaption = () => {
    return (
      <div className='job-candidates-slidout-caption'>
        <Button 
          theme={ButtonTheme.White} 
          onClick={handleContactDriverClick} 
          disabled={!activeJobInterest?.driverIsMessageable}
          title={activeJobInterest?.driverIsMessageable ? "" : "Driver may not be contacted"}
        >
          Contact Driver
        </Button>
      </div>
    );
  };
  
  const applySort = useCallback(() => {
    const sorts = activeSort;
    const customCompare = (left: any, right: any) => {    
      let compareVal = 0;
      if (sorts) {
        const userSortDefChecked = sorts.filter(sort => sort.checked);
        for (let idx = 0; idx < userSortDefChecked.length; idx++) {
          const sortDef = userSortDefChecked[idx];
          const sortDir = sortDef.sort;
          switch(sortDef.type) {
            case JobCandidateSort.Name:
              compareVal = left.driverName === right.driverName ? 0 : (left.driverName < right.driverName ? -1 : 1);
              break;
            case JobCandidateSort.CDL:
              compareVal = (left.cdl ?? '') === (right.cdl ?? '') ? 0 : ((left.cdl ?? '') < (right.cdl ?? '') ? -1 : 1);
              break;
            case JobCandidateSort.Status:
              compareVal = left.displayStatus === right.displayStatus ? 0 : (left.displayStatus < right.displayStatus ? -1 : 1);
              break;
            case JobCandidateSort.Matching:
              compareVal = matchCompare(left, right);;
              break;
          }
          if (compareVal !== 0) {
            return compareVal * (sortDir === 0 ? 1 : -1);
          }
        }
      }

      return compareVal;
    };
    setGridData(prevValue => {
      const newValue = [...prevValue];
      newValue.sort(customCompare);
      return newValue;
    });
  }, [activeSort]);

  useEffect(() => applySort(), [applySort]);

  useEffect(() => {
    const sort = getPreference(PreferenceType.JobCandidateSort)
    setActiveSort(sort);
  }, [getPreference]);
 
  const handleApplySorts = (sorts: Array<UserSort<JobCandidateSort>>) => {
    setToggleClearGridSortColumn(previousVal => !previousVal);
    if (sorts && sorts.length > 0)
      setPreference(PreferenceType.JobCandidateSort, sorts);
    else {
      deletePreference(PreferenceType.JobCandidateSort);
      setActiveSort(getPreference(PreferenceType.JobCandidateSort) as UserSort<JobCandidateSort>[]);
    }
  };

  const getSortNames = () => Object.keys(JobCandidateSort).filter((v) => isNaN(Number(v)));
  
  useEffect(() => {
    const filteredJobInterests = jobInterests.filter(ji => !searchFilter.length 
      || ji.driver.firstName.toLowerCase().includes(searchFilter.toLowerCase()) 
      || ji.driver.lastName.toLowerCase().includes(searchFilter.toLowerCase()));

    setGridData([...filteredJobInterests]);
    applySort();
  }, [searchFilter, jobInterests]);

  return (
    <div className='job-candidates'>
      <Navbar className='job-candidates-bar'>
        <NavStart>
          <SearchBox autoFocus onTextChange={(newValue: string) => setSearchFilter(newValue)} />
          <GridSort<JobCandidateSort> title='Sort Candidates' sortNames={getSortNames()} defaultSort={activeSort} handleApplySorts={handleApplySorts} />
        </NavStart>
      </Navbar>
      <main className='job-candidates-content'>
        <Slideout showInitially={false} toggle={toggleSlideout} onVisibleChange={onSlideoutVisibleChange} style={{ marginTop: '-24px'}} captionChildren={composeSlideoutCaption()}>
          { activeJobInterest && <DriverProfile model={activeJobInterest} /> }
        </Slideout>
        {
          <Grid<JobInterestModel> 
            columns={getColumns()} 
            data={gridData}
            gridRowClick={gridRowClickHandler}
            toggleClearSortColumn={toggleClearGridSortColumn}
          />
        }
      </main>
    </div>
  );
};

export default JobCandidates;