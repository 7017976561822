import { useState, HTMLAttributes, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useJobs } from '../../../context/JobsContext';
import { JobInterestModel, JobModel, JobStatus, CandidateSort, UNAUTHENTICATED } from '../../../Typings';
import { useQuestionnaire } from '../../../context/QuestionnaireContext';
import { getErrorMessage } from '../../../util/Graphql';
import { PreferenceType, usePref } from '../../../context/PreferenceContext';
import Button, { ButtonTheme } from '../../common/Button';
import Grid, { ColumnType } from '../../common/Grid';
import GridSort, { UserSort } from '../../common/GridSort';
import Navbar, { NavStart } from '../../common/Navbar';
import TabbarLayout from '../../layouts/TabbarLayout';
import MatchRibbon from './MatchRibbon';
import Divider from '../../common/Divider';
import Slideout from '../../common/Slideout';
import DriverProfile from './DriverProfile';
import SearchBox from '../../common/SearchBox';
import './CandidatesView.css';

interface ICandidatesViewProps extends HTMLAttributes<HTMLDivElement> {
  children?: never;
}

const CandidatesView = ({ ...props }: ICandidatesViewProps) => {
  const questionnaireService = useQuestionnaire();
  const { getPreference, setPreference, deletePreference } = usePref();
  const { jobs, findOrCreateConversationWithDriver } = useJobs();
  const [ jobInterests, setJobInterests ] = useState<Array<JobInterestModel>>([]);
  const [ activeJobInterest, setActiveJobInterest ] = useState<JobInterestModel>();
  const [ toggleOnSlideout, setToggleOnSlideout ] = useState<boolean | undefined>();
  const [ gridData, setGridData ] = useState<Array<JobInterestModel>>([]);
  const [ toggleClearGridSortColumn, setToggleClearGridSortColumn ] = useState(false);
  const [ searchFilter, setSearchFilter ] = useState<string>('');
  const [ activeSort, setActiveSort ] = useState<UserSort<CandidateSort>[]>(getPreference(PreferenceType.CandidateSort));
  const history = useHistory();

  const composeJobInterestMatch = (item: JobInterestModel) => <MatchRibbon matchScore={item.matchScore} className='match-ribbon' />;

  const getEndorsements =  useCallback((profile: any) => {
    const answerTextArray = questionnaireService.driverQuestionnaire?.getMultipleAnswerTextArray('endorsements', profile);
    if (Array.isArray(answerTextArray) && answerTextArray.length > 0) {
      return answerTextArray
        .filter(ans => ans.length > 0)
        .map(ans => ans[0].toUpperCase())
        .join(', ');
    }
    return null;
  }, [questionnaireService.driverQuestionnaire]);
  
  const getExperience = useCallback((profile: any) => {
    const experienceYears = questionnaireService.driverQuestionnaire?.getSingleAnswerText('experience', profile);
    return experienceYears ? `${experienceYears} years` : null;
  }, [questionnaireService.driverQuestionnaire]);

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'New': return 'var(--forest-green)';
      case 'Active': return 'var(--colorado-blue)';
      case 'Inactive': return 'var(--medium-gray)';
    }
  };

  const composeJobInterestStatus = (item: JobInterestModel) =>
    <div className='job-applicant fw-5'>
      <span style={{color: getStatusColor(item.status)}}>{item.displayStatus}</span>
    </div>;

  const numberCompare = useCallback((left: number, right: number) => {
    let sortVal = left === right ? 0 : left < right ? -1 : 1;
    return sortVal;
  }, []);

  const matchCompare = useCallback((left: any, right: any) => {
    return numberCompare(right.matchScore?.score ?? -1, left.matchScore?.score ?? -1);
  }, [numberCompare]);

  const jobTitleCompare = useCallback((left: any, right: any) => {
    let sortVal = left.jobTitle.localeCompare(right.jobTitle);
    if (sortVal === 0) {
      sortVal = matchCompare(right, left);
    }
    return sortVal;
  }, [matchCompare]);

  const statusCompare = useCallback((left: any, right: any) => {
    let sortVal = left.displayStatus.localeCompare(right.displayStatus);
    if (sortVal === 0) {
      sortVal = matchCompare(right, left);
    }
    return sortVal;
  }, [matchCompare]);

  const getColumns = () =>
    new Map([
      [ 'id', { type: ColumnType.Key } ],
      [ 'driverName', { caption: 'Name', type: ColumnType.Text } ],
      [ 'jobTitle', { caption: 'Job title', type: ColumnType.Text, sorter: (left: any, right: any) => jobTitleCompare(left, right) } ],
      [ 'status', { caption: 'Status', type: ColumnType.Custom, renderer: composeJobInterestStatus, sorter: (left: any, right: any) => statusCompare(left, right) } ],
      [ 'matchScore', { caption: 'Matching', type: ColumnType.Custom, renderer: composeJobInterestMatch, sorter: (left: any, right: any) => matchCompare(left, right) } ],
      [ 'cdl', { caption: 'CDL', type: ColumnType.Text } ],
      [ 'endorsements', { caption: 'Endorsements', type: ColumnType.Text, noSort: true } ],
      [ 'experience', { caption: 'Experience', type: ColumnType.Text, noSort: true } ],
      [ 'workRoutes', { caption: 'Work routes', type: ColumnType.Text, noSort: true } ],
    ]);

  useEffect(() => {
    const prevTitle = document.title;
    document.title = 'TruckerFy - Candidates';

    return () => { document.title = prevTitle; };
  }, []);

  useEffect(() => {
    if (jobs) {
      const tempJobInterests = jobs
        .filter(j => j.publicationStatus === JobStatus.Published)
        .flatMap((j: JobModel) => 
          j.jobInterests
            ?.map((ji: any) => ({
              id: ji.id,
              jobId: j.id,
              driverName: ji.driver.firstName + ' ' + ji.driver.lastName,
              jobTitle: j.internalTitle ?? j.title,
              status: ji.activeStatus,
              displayStatus: ji.displayStatus,
              matchScore: ji.matchScore,
              cdl: questionnaireService.driverQuestionnaire?.getSingleAnswerText('cdlClass', ji.driver.profile),
              endorsements: getEndorsements(ji.driver.profile),
              experience: getExperience(ji.driver.profile),
              workRoutes: questionnaireService.driverQuestionnaire?.getMultipleAnswerTextCsv('workRoutes', ji.driver.profile),
              driver: ji.driver,
              conversation: ji.conversation,
              driverIsMessageable: ji.driverIsMessageable
            }) as JobInterestModel) as Array<JobInterestModel>
        );

      setJobInterests(tempJobInterests as Array<JobInterestModel>);
    }
  }, [jobs, getEndorsements, getExperience, questionnaireService.driverQuestionnaire]);

  /*
  const handleGreatMatchButtonClick = (e: MouseEvent<HTMLButtonElement>) => setIsGreatMatch(prevValue => !prevValue);
  const handleGoodMatchButtonClick = (e: MouseEvent<HTMLButtonElement>) => setIsGoodMatch(prevValue => !prevValue);
  const handleWeakMatchButtonClick = (e: MouseEvent<HTMLButtonElement>) => setIsWeakMatch(prevValue => !prevValue);

  const composeFilterContent = () => (
    <div className='candidates-bar-filter-content off-black'>
      <p className='fs-12'>You'll be able to see all truck drivers that match the job post you created.</p>
      <p className='fw-5 fs-14 ls-02' style={{ marginTop: '25px' }}>Job Post</p>
      <Formik
          initialValues={{
            jobId: ''
          }}
          onSubmit={() => {}}
      >
        {() => (
          <>
            <FastField autoFocus component={Select} name='jobId' required={true}>
              <option key='0' value='' hidden>Job post</option>
              { 
                jobs?.filter(j => j.publicationStatus === JobStatus.Published)
                  .sort((a, b) => (a.internalTitle ?? a.title).localeCompare((b.internalTitle ?? b.title)))
                  .map((j, idx: number) => <option key={idx + 1} value={j.id}>{j.internalTitle ?? j.title}</option>) 
              }
            </FastField>
            <p className='fw-5 fs-14 ls-02' style={{ marginTop: '25px' }}>Matching level</p>
            <div className='candidates-bar-filter-content-match-container' style={{ marginTop: '-5px' }}>
              <StateButton className='match-great' selected={isGreatMatch} onClick={handleGreatMatchButtonClick}>Great match</StateButton>
              <StateButton className='match-good' selected={isGoodMatch} onClick={handleGoodMatchButtonClick}>Good match</StateButton>
              <StateButton className='match-weak' selected={isWeakMatch} onClick={handleWeakMatchButtonClick}>Weak match</StateButton>
            </div>
            <div className='candidates-bar-filter-content-button-container'>
              <Button type='button' theme={ButtonTheme.White}>Show Candidates</Button>
              <Button type='button' theme={ButtonTheme.White}>Clear</Button>
            </div>
          </>
        )}
      </Formik>
    </div>
  );
  */

  const applySort = useCallback(() => {
    const sorts = activeSort;
    const customCompare = (left: any, right: any) => {    
      let compareVal = 0;
      if (sorts) {
        const userSortDefChecked = sorts.filter(sort => sort.checked);
        for (let idx = 0; idx < userSortDefChecked.length; idx++) {
          const sortDef = userSortDefChecked[idx];
          const sortDir = sortDef.sort;
          switch(sortDef.type) {
            case CandidateSort.JobTitle:
              compareVal = left.jobTitle === right.jobTitle ? 0 : (left.jobTitle < right.jobTitle ? -1 : 1);
              break;
            case CandidateSort.Name:
              compareVal = left.driverName === right.driverName ? 0 : (left.driverName < right.driverName ? -1 : 1);
              break;
            case CandidateSort.CDL:
              compareVal = (left.cdl ?? '') === (right.cdl ?? '') ? 0 : ((left.cdl ?? '') < (right.cdl ?? '') ? -1 : 1);
              break;
            case CandidateSort.Status:
              compareVal = left.displayStatus === right.displayStatus ? 0 : (left.displayStatus < right.displayStatus ? -1 : 1);
              break;
            case CandidateSort.Matching:
              compareVal = matchCompare(left, right);;
              break;
          }
          if (compareVal !== 0) {
            return compareVal * (sortDir === 0 ? 1 : -1);
          }
        }
      }

      return compareVal;
    };
    setGridData(prevValue => {
      const newValue = [...prevValue];
      newValue.sort(customCompare);
      return newValue;
    });
  }, [activeSort]);

  useEffect(() => applySort(), [applySort]);

  const handleApplySorts = (sorts: Array<UserSort<CandidateSort>>) => {
    setToggleClearGridSortColumn(previousVal => !previousVal);
    if (sorts && sorts.length > 0)
      setPreference(PreferenceType.CandidateSort, sorts);
    else {
      deletePreference(PreferenceType.CandidateSort);
      setActiveSort(getPreference(PreferenceType.CandidateSort) as UserSort<CandidateSort>[]);
    }
  };

  const getSortNames = () => {
    const keys = Object.keys(CandidateSort).filter((v) => isNaN(Number(v)));
    keys[1] = 'Job title';
    return keys;
  };
  
  useEffect(() => {
    const sort = getPreference(PreferenceType.CandidateSort)
    setActiveSort(sort);
  }, [getPreference]);

  useEffect(() => {
    const filteredJobInterests = jobInterests.filter(ji => !searchFilter.length 
      || ji.driver.firstName.toLowerCase().includes(searchFilter.toLowerCase()) 
      || ji.driver.lastName.toLowerCase().includes(searchFilter.toLowerCase()));

    setGridData([...filteredJobInterests]);
    applySort();
  }, [searchFilter, jobInterests]);

  const composeToolbar = () => (
    <>
      <div className='candidates-view-header-buffer'></div>
      <Divider />
      <Navbar className='candidates-bar'>
        <NavStart>
          {/* <DropdownContainer 
            root={
              <div className='candidates-bar-sort-filter-container' title='Filter Candidates' >
                <FilterSvg/>
                <span>&nbsp;Filter</span>
              </div>
            }
          > */}
            {/* {composeFilterContent()} */}
          {/* </DropdownContainer> */}
          <SearchBox autoFocus onTextChange={(newValue: string) => setSearchFilter(newValue)} />
          <GridSort<CandidateSort> title='Sort Candidates' sortNames={getSortNames()} defaultSort={activeSort} handleApplySorts={handleApplySorts} />
        </NavStart>
      </Navbar>
    </>
  );

  const gridRowClickHandler = (key: any) => {
    const jobInterest = jobInterests.find(ji => ji.id === key);
    if (jobInterest) {
      setActiveJobInterest(jobInterest);
    }
    setToggleOnSlideout(prevValue => !prevValue);
  };

  const handleContactDriverClick = async () => {
    if (activeJobInterest?.jobId && activeJobInterest?.driver?.id) {
      const conversationId = await findOrCreateConversationWithDriver(activeJobInterest.jobId, activeJobInterest?.driver?.id)
        .catch((e: any) => {
          const errorMsg = getErrorMessage(e);
          if (errorMsg === UNAUTHENTICATED) {
            history.push('/login', { isAuthExpired: true });
          } else {
            history.push('/oops', { error: errorMsg });
          }
        });

      if (conversationId)
        history.push(`/dashboard/job/${activeJobInterest.jobId}/conversation/${conversationId}`)
    }
  };

  const composeSlideoutCaption = () => {
    return (
      <div className='job-candidates-slidout-caption'>
        <Button 
          theme={ButtonTheme.White} 
          onClick={handleContactDriverClick} 
          disabled={!activeJobInterest?.driverIsMessageable}
          title={activeJobInterest?.driverIsMessageable ? "" : "Driver may not be contacted"}
        >
          Contact Driver
        </Button>
      </div>
    );
  };

  return (
    <TabbarLayout>
      <header className='candidates-view-header'>
        {composeToolbar()}
      </header>
      <main className='candidates-view-main'>
        <Slideout showInitially={false} toggleOn={toggleOnSlideout} style={{ marginTop: '-24px'}} captionChildren={composeSlideoutCaption()}>
          { activeJobInterest && <DriverProfile model={activeJobInterest} /> }
        </Slideout>
        {
          <Grid<JobInterestModel>
            columns={getColumns()} 
            data={gridData}
            gridRowClick={gridRowClickHandler}
            toggleClearSortColumn={toggleClearGridSortColumn}
            // sortColumn={{ key: 'status', direction: SortDirection.Asc }} 
          />
        }
      </main>
    </TabbarLayout>
  );
};

export default CandidatesView;